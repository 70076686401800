import SocialLinks from "./social"

const brand = 'https://www.morphl2.io'

export const StayTuned = () => {
  return (
    <>
    <div className="flex flex-col w-full h-full max-w-7xl mx-auto px-4 sm:px-0">
      <div className="w-full flex items-center justify-center flex-row py-6 sm:py-10">
        <div className="w-[32px] h-[32px] sm:w-[32px] sm:h-[32px] morph-logo-notext"></div>
        <div className="flex-1"></div>
      </div>
      <div className='relative flex-1 flex flex-col items-center justify-center -mt-3 sm:-mt-7 pb-4'>
        <img src="/images/bg.png" className="absolute top-0 left-0 w-full h-full z-0" />
        <div className="relative flex flex-col items-center justify-center">
          <h2 className='text-[#14A800] font-denim font-bold text-center uppercase text-[56px] leading-[50px] sm:text-[120px] sm:leading-[100px]'>
            <br />
            <span>Stay tuned</span>
          </h2>
          <img src="/images/working.svg" alt="working" className='text-center mt-5 sm:mt-0 w-[180px] h-[180px] sm:w-[250px] sm:h-[250px]' />
          <div className='text-center text-[#1A1A1A] text-[20px] sm:text-[24px] font-bold'>The website under maintenance</div>
          <div className='text-center text-[#999999] text-[14px] mt-3 max-w-[426px]'>We apologize for any inconveniences caused and we will be online as soon as possible. Please check again later, thank you.</div>

          <div className="mt-6 sm:mt-6 flex items-center">
            <SocialLinks
              bgClass="w-10 h-10 rounded-full bg-[#14A80012]"
              size="16"
              className="flex flex-row gap-10 text-[#14A800]"
              fill="#14A800"
              slice={3}
            />
          </div>
        </div>
      </div>
      <div className="mt-[50px] sm:mt-0 w-full flex flex-col items-center justify-center sm:flex-row py-2 sm:py-4">
        <div
          className="hidden sm:flex w-[100px] h-[34.5px] sm:w-[106px] sm:h-[36.5px] morph-logo"
        />
        <div className="flex-1 copy-right text-xs text-right text-[#90918D]">
          © {new Date().getFullYear()} <a 
            target={brand.startsWith("http") ? "_blank" : "_self"}
            href={brand}
          >Morph</a>
          . All rights reserved
        </div>
      </div>
    </div>
    </>
  )
}