import { Box, chakra } from '@chakra-ui/react';
import React from 'react';

import type { GasPriceInfo } from 'types/api/stats';

import { space } from 'lib/html-entities';
import GasPrice from 'ui/shared/gas/GasPrice';
import { Console } from 'lib/console';

interface Props {
  name: string;
  info: GasPriceInfo | null;
}

const GasInfoTooltipRow = ({ name, info }: Props) => {
  Console.log('info.time', info);
  return (
    <>
      <Box color="text_secondary">
        <chakra.span color="text_secondary">{ name }</chakra.span>
        { info && typeof info.time === 'number' && info.time > 0 && (
          <chakra.span color="text_secondary">
            { space }{ (info?.time / 1000).toLocaleString(undefined, { maximumFractionDigits: 1 }) }s
          </chakra.span>
        ) }
      </Box>
      {/* <GasPrice data={ info } textAlign="right" color="text" /> */}
      <GasPrice data={ info } unitMode="secondary" color="text" textAlign="right"/>
    </>
  );
};

export default React.memo(GasInfoTooltipRow);
