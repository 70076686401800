import { getEnvValue } from 'configs/app/utils';
import dayjs from 'lib/date/dayjs';
import { getFromLocalStorage } from './storage';

export const Console = (function() {
  if (typeof window === 'undefined') return console;
  const env = [ 'devnet', 'qanet', undefined ].includes(getEnvValue('NEXT_PUBLIC_NETWORK_TYPE')) || getFromLocalStorage('morph.Console') || window?.location.hostname === "localhost" ;

  return {
    log: function(...text: any) {
      if (!env) {
        return;
      }
      // eslint-disable-next-line no-console
      console.log(dayjs(new Date()).format('YYYY-M-D h:m:s'), ...text);
    },
    info: function(...text: any) {
      if (!env) {
        return;
      }
      // eslint-disable-next-line no-console
      console.info(dayjs(new Date()).format('YYYY-M-D h:m:s'), ...text);
    },
    warn: function(...text: any) {
      if (!env) {
        return;
      }
      // eslint-disable-next-line no-console
      console.warn(dayjs(new Date()).format('YYYY-M-D h:m:s'), ...text);
    },
    error: function(...text: any) {
      // eslint-disable-next-line no-console
      console.error(dayjs(new Date()).format('YYYY-M-D H:mm:ss'), ...text);
    },
  };
})();
