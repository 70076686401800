export const brandMetadata = {
  title: `Morph ${process.env.NEXT_PUBLIC_NETWORK_TYPE} Bridge`,
  description:
    "Upgrade your blockchain experience with Morph - the secure, decentralized, cost-efficient, and high-performing EVM-equivalent rollup. Try it now!",
  keyword:
    "Morph, scaling solution, Morph testnet, Morph mainnet, Morph roadmap, optimistic zk-rollup, validity proofs, bridge,cross-chain",
};

const docUrl = process.env.MORPH_DOC_URL || "https://docs.morphl2.io";
const explorerApi =
  process.env.MORPH_EXPLORER_API || "https://explorer-api.morphl2.io";
const explorer = process.env.MORPH_EXPLORER || "https://explorer.morphl2.io";
const bridge = process.env.MORPH_BRIDGE || "https://bridge.morphl2.io";

const ethFaucet = process.env.MORPH_ETH_FAUCET || "https://sepoliafaucet.com/";
const usdtFaucet =
  process.env.MORPH_USDT_FAUCET ||
  "https://discord.com/channels/1156486804661338162/1199665829730582620";

export const configs = {
  brand: "https://www.morphl2.io/",

  quickStart: "https://docs.morphl2.io/",

  howItWorks: "https://docs.morphl2.io/docs/how-morph-works/intro/",
  contractMoohism: "https://forms.gle/dw5PKY8r4N8R1YbGA",
  joinJourney: "https://forms.gle/dw5PKY8r4N8R1YbGA",
  exploreMore:
    "https://docs.morphl2.io/docs/how-morph-works/responsive-validity-proof/overview/",

  // start building
  apiDocs: explorer + "/api-docs",
  explorer: explorer,
  explorerApi,
  bridge: bridge,

  contactUs: "/about",
  terms: "https://morphl2.io/terms",
  policy: "/privacy_policy",
  about: "/about",
  wallet: "/wallet",
  build: "/build",

  learnmore: docUrl + "/docs/how-morph-works/intro/",
  tutorial: docUrl + "/docs/quick-start/bridge/",
  doc: docUrl + "/docs/how-morph-works/intro/",

  // social
  medium: "https://medium.com/@morphlayer2",
  twitter: "https://twitter.com/Morphl2",
  telegram: "https://t.me/MorphL2official",
  // 'https://t.me/+qslsWvH2_-1iMTdl',
  discord: "https://discord.gg/L2Morph",
  linkedIn: "http://www.linkedin.com/company/morphl2",

  facebook: "https://www.facebook.com/profile.php?id=61554448708419",
  tiktok: "https://www.tiktok.com/@morphl2",
  youtube: "https://www.youtube.com/channel/UCZW6iBpnbpCzYOrMY-RtDOw",

  github: "", // https://github.com/morph-l2

  blog: "https://blog.morphl2.io",
  forum: "https://forum.morphl2.io",

  faucet: "",

  sepoliafaucet: ethFaucet,
  usdtfaucet: usdtFaucet,

  community: "https://twitter.com/Morphl2",
};
