import { Console } from 'lib/console';

import api from './api';
import api2 from './api2';
import app from './app';
import chain, { l1Chain } from './chain';
import * as features from './features';
import meta from './meta';
import services from './services';
import UI from './ui';

const config = Object.freeze({
  app,
  chain,
  l1Chain,
  api,
  api2,
  UI,
  features,
  services,
  meta,
});

Console.warn('config', config);
export default config;
