import type { Feature } from './types';

import { getEnvValue } from '../utils';

const specUrl = getEnvValue('NEXT_PUBLIC_API_SPEC_URL') || `https://raw.githubusercontent.com/blockscout/blockscout-api-v2-swagger/main/swagger.yaml`;
const specUrl2 = getEnvValue('NEXT_PUBLIC_API_SPEC_URL2') || ``;


const title = 'API Docs';

const config: Feature<{ specUrl: string, specUrl2: string }> = (() => {
  return Object.freeze({
    title,
    isEnabled: true,
    specUrl,
    specUrl2,
  });
})();

export default config;
