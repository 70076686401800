// eslint-disable-next-line no-restricted-imports
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import minMax from 'dayjs/plugin/minMax';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

import { nbsp } from 'lib/html-entities';

const relativeTimeConfig = {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'ss', r: 59, d: 'second' },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 6, d: 'day' },
    { l: 'w', r: 1 },
    { l: 'ww', r: 4, d: 'week' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y', r: 17 },
    { l: 'yy', d: 'year' },
  ],
};

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime, relativeTimeConfig);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(weekOfYear);
dayjs.extend(minMax);

const relativeTimeConf = {
  s: '1 s',
  ss: '%d s',
  future: 'in %s',
  past: '%s ago',
  m: '1 m',
  mm: '%d m',
  h: '1 h',
  hh: '%d h',
  d: '1 d',
  dd: '%d d',
  w: '1 w',
  ww: '%d w',
  M: '1 mo',
  MM: '%d mo',
  y: '1 yr',
  yy: '%d yr',
};

dayjs.updateLocale('en', {
  formats: {
    llll: `MMM DD YYYY HH:mm:ss A (Z${ nbsp }UTC)`,
  },
  relativeTime: relativeTimeConf,
});

dayjs.locale('en');

dayjs.tz.setDefault("Asia/Shanghai")

export const fromNow = (time: Dayjs | string | number | null) => {
  if (!time) {
    return '';
  }
  const day = dayjs(time);
  // @ts-ignore
  day.$locale().relativeTime = relativeTimeConf;
  return day.fromNow();
};

export const afterNow = (time: number) => {
  if (!time) {
    return '';
  }
  const day = dayjs(time);
  // @ts-ignore
  day.$locale().relativeTime = relativeTimeConf;
  return dayjs(new Date()).from(dayjs(new Date()).add(time, 'seconds')).replace('ago', 'left');
};

export default dayjs;
