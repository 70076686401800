'use client'

import { configs } from "./config";
import classNames from "classnames";
import {
  Discord,
  Github,
  Medium,
  Telegram,
  TwitterX,
} from "@morui/shared-icons";

const socialLinks = [
  {
    label: "Twitter",
    link: configs.twitter,
    img: (fill: string, size?: string) => <TwitterX fill={fill} size={size} />,
    log_name: "twitter:_click",
    log_id: "b3699",
  },
  {
    label: "Discord",
    link: configs.discord,
    img: (fill: string, size?: string) => <Discord fill={fill} size={size} />,
  },
  {
    label: "Telegram",
    link: configs.telegram,
    img: (fill: string, size?: string) => <Telegram fill={fill} size={size} />,
  },
  {
    label: "Github",
    link: configs.github,
    img: (fill: string, size?: string) => <Github fill={fill} size={size} />,
  },
  {
    label: "Medium",
    link: configs.medium,
    img: (fill: string, size?: string) => <Medium fill={fill} size={size} />,
    log_name: "medium:_click",
    log_id: "b3700",
  },
];

export default function SocialLinks(props: {
  slice?: number;
  className?: string;
  bgClass?: string;
  fill?: string;
  size?: string;
}) {
  const configs = socialLinks
    .filter((v) => v.link)
    .slice(0, props.slice || socialLinks.length);
  return (
    <div
      className={classNames("text-b-white", props.className, {
        [`grid-cols-${configs.length}`]: true,
      })}
    >
      {configs.map((val, key: number) => {
        return (
          <a
            key={key}
            href={val.link || ""}
            className={
              props.bgClass + " social-link flex justify-center items-center"
            }
            target={val.link.startsWith("http") ? "_blank" : "_self"}
          >
            <span className="inline-block w-6 h-6 flex items-center justify-center">
              {val.img(props.fill || "", props.size || "18")}
            </span>
            <span className="sr-only ml-3 -mt-1 social-label">{val.label}</span>
          </a>
        );
      })}
    </div>
  );
}
