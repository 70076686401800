import { Box, Grid, Flex, Text, VStack, Skeleton } from '@chakra-ui/react';
import React from 'react';

import ColorModeToggler from '../header/ColorModeToggler';

import config from 'configs/app';
import NetworkAddToWallet from 'ui/shared/NetworkAddToWallet';
import NetworkLogo from '../networkMenu/NetworkLogo';
import FooterLinkItem from './FooterLinkItem';

const MAX_LINKS_COLUMNS = 3;

const Footer = () => {
  const linksData = config.UI.footer.links;
  // useQuery<unknown, ResourceError<unknown>, Array<CustomLinksGroup>>(
  //   {
  //     queryKey: [ 'footer-links' ],
  //     queryFun: () => config.UI.footer.links, // fetch(config.UI.footer.links || ''),
  //     enabled: Boolean(config.UI.footer.links),
  //     staleTime: Infinity,
  //   }
  // );
  const isLoading = false;

  return (
    <Box>
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        p={{ base: 4, lg: 9 }}
        borderTop="1px solid"
        borderColor="divider"
        as="footer"
        columnGap="100px"
      >
        <Box flexGrow="1" mb={{ base: 8, lg: 0 }}>
          <Flex flexWrap="wrap" columnGap={ 8 } rowGap={ 6 }>
            <ColorModeToggler/>
            { /* { !config.UI.indexingAlert.isHidden && <IndexingAlertIntTxs/> } */ }
             <NetworkAddToWallet/> 
          </Flex>
          <Box display="flex" alignItems="center" my={{ base: 4, lg: '4' }}>
            <NetworkLogo />
            { config.chain.networkType && (
              <Box as="div">
                <Box as="span" className="rounded px-1 ml-1 text-xs text-[#846DFF] border border-[#846DFF]">{ config.chain.networkType }</Box>
                { /* <Icon as={ testnetIcon } h="14px" w="auto" color="red.400" pl={ 1 }/> */ }
              </Box>
            ) }
            { /* <Link fontSize="xs" href="https://www.morphl2.io">morphl2.io</Link> */ }
          </Box>
          <Text maxW={{ base: 'unset', lg: '470px' }} fontSize="xs">
          Building an Ecosystem for Value-Driven Dapps.
          </Text>
          { /* <VStack spacing={ 1 } mt={ 6 } alignItems="start">
            { apiVersionUrl && (
              <Text fontSize="xs">
                  Backend: <Link href={ apiVersionUrl } target="_blank">{ backendVersionData?.backend_version }</Link>
              </Text>
            ) }
            { (config.UI.footer.frontendVersion || config.UI.footer.frontendCommit) && (
              <Text fontSize="xs">
                Frontend: <Link href={ FRONT_VERSION_URL } target="_blank">{ config.UI.footer.frontendVersion }</Link>
              </Text>
            ) }
          </VStack> */ }
        </Box>
        <Grid
          gap={{ base: 6, lg: 12 }}
          gridTemplateColumns={ config.UI.footer.links ?
            { base: 'repeat(auto-fill, 160px)', lg: `repeat(${ (linksData?.length || MAX_LINKS_COLUMNS) }, 160px)` } :
            'auto'
          }
        >
          { /* <Box minW="160px" w={ config.UI.footer.links ? '160px' : '100%' }>
            { config.UI.footer.links && <Text fontWeight={ 500 } mb={ 3 }>Morph</Text> }
            <Grid
              gap={ 1 }
              gridTemplateColumns={ config.UI.footer.links ? '160px' : { base: 'repeat(auto-fill, 160px)', lg: 'repeat(3, 160px)' } }
              gridTemplateRows={{ base: 'auto', lg: config.UI.footer.links ? 'auto' : 'repeat(2, auto)' }}
              gridAutoFlow={{ base: 'row', lg: config.UI.footer.links ? 'row' : 'column' }}
              mt={{ base: 0, lg: config.UI.footer.links ? 0 : '100px' }}
            >
              { BLOCKSCOUT_LINKS.map(link => <FooterLinkItem { ...link } key={ link.text }/>) }
            </Grid>
          </Box> */ }
          { config.UI.footer.links && isLoading && (
            Array.from(Array(3)).map((i, index) => (
              <Box minW="160px" key={ index }>
                <Skeleton w="120px" h="20px" mb={ 6 }/>
                <VStack spacing={ 5 } alignItems="start" mb={ 2 }>
                  { Array.from(Array(5)).map((i, index) => <Skeleton w="160px" h="14px" key={ index }/>) }
                </VStack>
              </Box>
            ))
          ) }
          { config.UI.footer.links && linksData && (
            linksData.slice(0, MAX_LINKS_COLUMNS).map(linkGroup => {
              if (!linkGroup.display) {
                return null;
              }
              return (
                <Box minW="160px" key={ linkGroup.title }>
                  <Text fontWeight={ 500 } mb={ 3 }>{ linkGroup.title }</Text>
                  <VStack spacing={ 1 } alignItems="start">
                    { linkGroup?.links.map(link => <FooterLinkItem { ...link } key={ link.text }/>) }
                  </VStack>
                </Box>
              );
            })
          ) }
        </Grid>
      </Flex>

      <Box borderTop="1px solid" borderColor="divider" lineHeight="48px" fontSize="14px" textAlign="center">
        © {new Date().getFullYear()} <a target="_blank" href={ config.UI.footer.brandLink }>Morph</a>. All rights reserved
      </Box>
    </Box>
  );
};

export default Footer;
