export default function shortenString(
  string: string | null | undefined,
  startLen = 6,
  endLen = 4,
) {
  if (!string) {
    return '';
  }

  if (string.length <= 10 || string.length <= startLen + endLen) {
    return string;
  }

  return string.slice(0, startLen) + '...' + string.slice(-1 * endLen);
}
