import { getEnvValue } from './utils';


const logAppName = getEnvValue('NEXT_PUBLIC_LOG_APP_NAME');
const tokenList = getEnvValue('NEXT_PUBLIC_MORPH_BRIDGE_TOKEN_LIST');
const appPort = getEnvValue('NEXT_PUBLIC_APP_PORT');
const appSchema = getEnvValue('NEXT_PUBLIC_APP_PROTOCOL');
const appHost = getEnvValue('NEXT_PUBLIC_APP_HOST');
const baseUrl = [
  appSchema || 'https',
  '://',
  appHost,
  appHost?.startsWith('localhost') ? (appPort && ':' + appPort) : '',
].filter(Boolean).join('');
const isDev = getEnvValue('NEXT_PUBLIC_APP_ENV') === 'development';

const app = Object.freeze({
  isDev,
  logAppName,
  tokenList,
  protocol: appSchema,
  host: appHost,
  port: appPort,
  baseUrl,
  useProxy: getEnvValue('NEXT_PUBLIC_USE_NEXT_JS_PROXY') === 'true',
});

export default app;
