import { Flex, chakra, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import useThemeColors from 'ui/shared/monaco/utils/useThemeColors';

interface Props {
  className?: string;
  children: React.ReactNode;
}

const MainColumn = ({ children, className }: Props) => {
  const bgColor = useColorModeValue('#f5f5f5', '#101112');
  return (
    <Flex
      className={ 'main-column ' + className }
      flexDir="column"
      flexGrow={ 1 }
      w={{ base: '100%', lg: 'auto' }}
      paddingX={{ base: 3, lg: config.UI.navigation.layout === 'horizontal' ? 6 : 12 }}
      paddingTop={{ base: `${ 12 + 52 }px`, lg: 6 }} // 12px is top padding of content area, 52px is search bar height
      paddingBottom={ 8 }
      backgroundColor={bgColor}
    >
      { children }
    </Flex>
  );
};

export default React.memo(chakra(MainColumn));
