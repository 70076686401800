'use client'

import React, { ReactNode } from 'react'
import { State, WagmiProvider } from 'wagmi'
import { createAppKit } from '@reown/appkit/react'
import { reconnect } from '@wagmi/core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { getFromLocalStorage, isInLocalStorage } from 'lib/storage'

import { chains, config, projectId, wagmiAdapter, metadata } from './config'

// Setup queryClient
const queryClient = new QueryClient()

if (!projectId) throw new Error('Project ID is not defined')

const theme = getFromLocalStorage("color-theme") || (!isInLocalStorage("color-theme") ? "light" : "dark") || "light";

reconnect(config)

// Create modal
createAppKit({
  adapters: [wagmiAdapter],
  networks: [chains[0], ...chains.slice(1)],
  defaultNetwork: chains?.[0],
  projectId,
  metadata,
  features: {
    analytics: false,
    swaps: false,
    onramp: false,
    ...(process.env.MORPH_WALLETCONNECT_SOCIAL_LOGIN ? {
      email: true, // default to true
      socials: ['x', 'discord', 'github', 'facebook', 'google', 'apple'],
      emailShowWallets: true, // default to true
    } : {
      email: false,
      socials: [],
      emailShowWallets: false
    }),
  },
  enableWalletConnect: true,
  // enableEIP6963: true,
  // enableCoinbase: true,
  allWallets: 'HIDE',
  themeVariables: {
    "--w3m-accent": "#14A800",
    "--w3m-color-mix": "#fff",
    "--w3m-color-mix-strength": 40,
    "--w3m-border-radius-master": "2px",
    "--w3m-z-index": 100001,
  },
  featuredWalletIds: [
    // https://walletconnect.com/explorer/metamask
    "38f5d18bd8522c244bdd70cb4a68e0e718865155811c043f052fb9f1c51de662", // bitget wallet
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96", // metamask
    "18388be9ac2d02726dbac9777c96efaac06d744b2f6d580fccdd4127a6d01fd1", // Rabby
    "e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b", // wallectconnect
    "c7708575a2c3c9e6a8ab493d56cdcc56748f03956051d021b8cd8d697d9a3fd2", // fox wallet
    // "ef333840daf915aafdc4a004525502d6d49d77bd9c65e0642dbaefb3c2893bef", // imtoken
  ],
  excludeWalletIds: [
    'walletConnect'
  ],
  allowUnsupportedChain: true,
  chainImages: {
    // qanet
    53077: 'https://bridge-holesky.morphl2.io/v4/Logo/Morphi-L2-white.svg',
    900: 'https://bridge-holesky.morphl2.io/images/logo/holesky.svg',
    // holesky
    2810: 'https://bridge-holesky.morphl2.io/v4/Logo/Morphi-L2-white.svg',
    17000: 'https://bridge-holesky.morphl2.io/images/logo/holesky.svg',
    // sepolia
    2710: 'https://bridge-holesky.morphl2.io/logo/svg/logo-l2.svg',
    11155111: 'https://bridge-holesky.morphl2.io/images/logo/sepolia.svg',
    // mainnet
    1: 'https://bridge-holesky.morphl2.io/images/logo/ethereum2.svg',
    2818: 'https://bridge-holesky.morphl2.io/images/logo/morph2.svg',
  },
  themeMode: theme as any,
  termsConditionsUrl: 'https://www.morphl2.io/terms',
  privacyPolicyUrl: ''
})

export default function Web3ModalProvider({
  children,
  initialState
}: {
  children?: ReactNode
  initialState?: State
}) {
  return (
    <WagmiProvider config={config} initialState={initialState} reconnectOnMount={true}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
