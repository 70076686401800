// save to storage
export const saveToLocalStorage = (key: string, value: string | object) => {
  if(typeof window !== 'undefined') {
    if (typeof value !== 'string') value = window.JSON.stringify({...value})
    return window.localStorage.setItem(key, value);
  }
}

// get from storage
export const getFromLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    const val = window.localStorage.getItem(key);

    try {
      return JSON.parse(val || '')
    } catch(err) {
      return val;
    }
  }
}

export const isInLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    return key in localStorage
  }
  return false
}