import { Console } from './console';
import Log from 'web-sdk';
import { getEnvValue } from 'configs/app/utils';

type IPageInfoList = Array<{
  path: string;
  curr_page: string;
  brand: string;
  business1: string;
  business2: string;
  event_tracking: string;
  event_tracking_id: string;
  event_tracking_ext: {
    usr_language: string;
  };
}>;

let userLang: string;
let pageInfoList: IPageInfoList;
const baseURL = 'https://log.noxiaohao.com';

const webName = 'mph_web';

const brand = 'morphism';
const business1 = 'morphism';
const business2 = 'website';

const pathnames = {
  '/': 'website_page',
};

const events = {
  // testnet explorer
  testnet_explorer_page: 'b4301',
  'testnet_explorer_blocks:_click': 'b4306',
};

export const log = ({
  page = getEnvValue('NEXT_PUBLIC_LOG_APP_NAME') || 'testnet_explorer',
}: {
  page: string;
}) => {
  if (typeof window !== 'undefined') {
    if (location.origin.startsWith('http://localhost')) return;
    // @ts-ignore
    userLang = navigator.language || navigator.userLanguage;

    pageInfoList = [ {
      path: '',
      curr_page: '/',
      brand,
      business1,
      business2,
      event_tracking: `${ webName }_${ page }`,
      event_tracking_id: `${ events[page as keyof typeof events] || '' }`,
      event_tracking_ext: {
        usr_language: userLang,
      },
    } ];
    // TODO (Jayce) hide code in development mode
    return new Log({
      app_id: 'xyz.morphism.pc',
      app_name: webName,
      // ver: '',
      // uid: '',
    }, pageInfoList, {
      baseURL,
    });
  }
};

export const websiteTabClick = ({
  event,
  page = getEnvValue('NEXT_PUBLIC_LOG_APP_NAME') || 'testnet_explorer',
}: {
  event: string;
  page?: string;
}) => {
  if (typeof window === 'undefined') {
    return;
  }

  page = pathnames[location.pathname as keyof typeof pathnames] || pathnames['/'];

  try {
    log({
      page,
    })?.click({
      business1,
      business2,
      event_tracking: `${ webName }_${ event }`,
      event_tracking_id: events[event as keyof typeof events],
      event_tracking_ext: {
        user_language: userLang,
      },
    });
  } catch (err) {
    Console.log(`websiteTabClick ${ event } ${ events[event as keyof typeof events] }`, err);
  }
};
